import {
	MainStyled,
	StyledSwiper,
	SecondStyledSwiper,
	ThirdStyledSwiper,
	LastStyledSwiper,
	BoothStyledSwiper,
} from './components/styled';
import { useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import Main from './components/sections/Main';
import Introduction from './components/sections/Introduction';
import Share from './components/sections/Share';
import Sponsorship from './components/sections/Sponsorship';
import './style.css';
import { SwiperSlide } from 'swiper/react';
import SwiperButtonPrev from './components/controllers/SwiperButtonPrev';
import SwiperButtonNext from './components/controllers/SwiperButtonNext';
import SwiperPaginationProgressBar from './components/controllers/SwiperPaginationProgressBar';
import FAQ from './components/sections/FAQ';
import SponsorshipDetail from './components/sections/SponsorshipDetail';
import {
	Navigation,
	Pagination,
	Scrollbar,
	Mousewheel,
	FreeMode,
} from 'swiper';
import BoothDetails from './components/sections/BoothDetails';
import TimeTable from './components/sections/TimeTable';
import iconFloatPageUp from './assets/svgs/ic_float_pageup.svg';
import SponsoringCompanies from './components/sections/SponsoringCompanies';

const App = () => {
	// 모바일/데스크탑 전환에 필요한 state
	const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
	const [windowInnerHeight, setWindowInnerHeight] = useState(
		window.innerHeight
	);
	const [mobile, setMobile] = useState(window.innerWidth < 1279);

	// 전체 swiper에 필요한 섹션 담을 state
	const [sections, setSections] = useState([]);

	// swiper 전환에 필요한 slide index state
	const [firstSwiperCurrentPage, setFirstSwiperCurrentPage] = useState(0);
	const [secondSwiperCurrentPage, setSecondSwiperCurrentPage] = useState(0);

	// 기업 후원 안내 토글 state
	const [sponsorshipDetail, setSponsorshipDetail] = useState(false);

	// 링크로 공유하기 버튼 클릭 시 alert visibility state
	const [toastVisible, setToastVisible] = useState(false);

	// swiper control하기 위한 useRef훅
	const swiperRef = useRef();
	const secondSwiperRef = useRef();
	const thirdSwiperRef = useRef();
	const lastSwiperRef = useRef();

	/**
	 * swiper 전환 함수
	 * @param {number} number번째 swiper로 전환
	 */
	const changeActiveSwiperTo = (number) => {
		if (number === 1) {
			swiperRef.current.mousewheel?.enable();
			secondSwiperRef.current.mousewheel?.disable();
			thirdSwiperRef.current.mousewheel?.disable();
			lastSwiperRef.current?.mousewheel?.disable();
		} else if (number === 2) {
			swiperRef.current.mousewheel?.disable();
			secondSwiperRef.current.mousewheel?.enable();
			thirdSwiperRef.current.mousewheel?.disable();
			lastSwiperRef.current?.mousewheel?.disable();
		} else if (number === 3) {
			swiperRef.current.mousewheel?.disable();
			secondSwiperRef.current.mousewheel?.disable();
			thirdSwiperRef.current.mousewheel?.enable();
			lastSwiperRef.current?.mousewheel?.disable();
		} else if (number === 4) {
			swiperRef.current.mousewheel?.disable();
			secondSwiperRef.current.mousewheel?.disable();
			thirdSwiperRef.current.mousewheel?.disable();
			lastSwiperRef.current?.mousewheel?.enable();
		}
	};

	const makeEmptySlide = (number) => {
		if (!mobile) return;

		const array = [];

		for (let i = 0; i < number; i++) {
			array.push(i);
		}

		return array;
	};

	// 브라우저 너비에 따라 모바일/데스크탑 전환
	useEffect(() => {
		setMobile(windowInnerWidth < 1279);
	}, [windowInnerWidth]);

	useEffect(() => {
		const setInnerWidth = () => setWindowInnerWidth(window.innerWidth);
		window.addEventListener('resize', setInnerWidth);

		return () => {
			window.removeEventListener('resize', setInnerWidth);
		};
	}, []);

	// 랜딩 페이지에 필요한 section 및 swiper 초기 세팅
	useEffect(() => {
		setSections([
			{
				id: 0,
				title: 'Main',
				element: <Main mobile={mobile} />,
			},
			{
				id: 1,
				title: 'Introduction',
				element: <Introduction />,
			},
			{
				id: 2,
				title: 'Booth Details',
				element: mobile ? (
					<BoothStyledSwiper
						speed={1000}
						modules={[Mousewheel, FreeMode]}
						direction="vertical"
						slidesPerView={1}
						mousewheel={{ enabled: false, sensitivity: 0.5 }}
						allowTouchMove={true}
						freeMode={{ enabled: true, sticky: false }}
					>
						<SwiperSlide>
							<BoothDetails mobile={mobile} />
						</SwiperSlide>
						<SwiperSlide className="duplicate" />
					</BoothStyledSwiper>
				) : (
					<BoothDetails />
				),
			},
			{
				id: 3,
				title: 'Presenters',
				element: (
					<SecondStyledSwiper
						speed={1000}
						className="second"
						modules={[Mousewheel, FreeMode]}
						direction="vertical"
						slidesPerView={1}
						mousewheel={{ enabled: false, sensitivity: 0.5 }}
						allowTouchMove={true}
						freeMode={{ enabled: true, sticky: false }}
						onSwiper={(swiper) => (secondSwiperRef.current = swiper)}
						onReachBeginning={() => changeActiveSwiperTo(1)}
						onReachEnd={() => changeActiveSwiperTo(1)}
						onSlideChange={(e) => setSecondSwiperCurrentPage(e.activeIndex)}
						onScroll={(e) => {
							if (e.isBeginning) {
								changeActiveSwiperTo(1);
							} else if (e.isEnd) {
								changeActiveSwiperTo(1);
							}
						}}
					>
						{/* <SwiperSlide>
							<Presenters
								secondSwiper={secondSwiperRef}
								changeActiveSwiperTo={changeActiveSwiperTo}
								mobile={mobile}
								setSecondSwiperEnabled={setSecondSwiperEnabled}
							/>
						</SwiperSlide> */}
						<SwiperSlide>
							<TimeTable mobile={mobile} />
						</SwiperSlide>
						<SwiperSlide className="duplicate"></SwiperSlide>
						<SwiperSlide className="duplicate"></SwiperSlide>
						<SwiperSlide className="duplicate"></SwiperSlide>
						<SwiperSlide className="duplicate"></SwiperSlide>
						<SwiperSlide className="duplicate"></SwiperSlide>
						{mobile && (
							<>
								{makeEmptySlide(
									Math.floor((7500 - innerHeight) / innerHeight)
								)?.map((_, index) => (
									<SwiperSlide
										key={`${index}-duplicate`}
										className="duplicate"
									/>
								))}
							</>
						)}
						<div
							className={`floating-button ${
								secondSwiperCurrentPage >= 1 ? ' visible' : ' hidden'
							}`}
							onClick={() => secondSwiperRef.current?.slideTo(0)}
						>
							<img src={iconFloatPageUp} alt="" />
						</div>
					</SecondStyledSwiper>
				),
			},
			{
				id: 4,
				title: 'Sponsoring Company',
				element: (
					<ThirdStyledSwiper
						speed={1000}
						modules={[Mousewheel, FreeMode]}
						direction="vertical"
						slidesPerView={1}
						mousewheel={{ enabled: false, sensitivity: 0.5 }}
						allowTouchMove={true}
						freeMode={{ enabled: true, sticky: false }}
						onSwiper={(swiper) => (thirdSwiperRef.current = swiper)}
						onReachEnd={() => changeActiveSwiperTo(1)}
						onReachBeginning={() => changeActiveSwiperTo(1)}
						onScroll={(e) => {
							if (e.isBeginning) {
								changeActiveSwiperTo(1);
							} else if (e.isEnd) {
								changeActiveSwiperTo(1);
							}
						}}
					>
						<SwiperSlide key="sponsoring-companies">
							<SponsoringCompanies />
						</SwiperSlide>
						<SwiperSlide key="duplicate" className="duplicate" />
						<SwiperSlide key="duplicate" className="duplicate" />
						{mobile && (
							<>
								{makeEmptySlide(
									Math.floor((4000 - innerHeight) / innerHeight)
								)?.map((_, index) => (
									<SwiperSlide
										key={`${index}-duplicate`}
										className="duplicate"
									/>
								))}
							</>
						)}
					</ThirdStyledSwiper>
				),
			},
			{
				id: 5,
				title: 'Share',
				element: <Share mobile={mobile} setToastVisible={setToastVisible} />,
			},
			{
				id: 6,
				title: 'FAQ',
				element: <FAQ mobile={mobile} />,
			},
			{
				id: 7,
				title: 'Sponsorship',
				element: (
					<LastStyledSwiper
						speed={1000}
						modules={[Mousewheel, FreeMode]}
						direction="vertical"
						slidesPerView={1}
						mousewheel={{ enabled: false, sensitivity: 0.5 }}
						allowTouchMove={true}
						freeMode={{ enabled: true, sticky: false }}
						onSwiper={(swiper) => {
							lastSwiperRef.current = swiper;
						}}
						onReachBeginning={() => {
							changeActiveSwiperTo(1);
							setSponsorshipDetail(false);
						}}
					>
						<SwiperSlide>
							<Sponsorship
								swiperRef={swiperRef.current}
								lastSwiperRef={lastSwiperRef}
								mobile={mobile}
								changeActiveSwiperTo={changeActiveSwiperTo}
								setSponsorshipDetail={setSponsorshipDetail}
							/>
						</SwiperSlide>
						{(mobile || sponsorshipDetail) && (
							<>
								{makeEmptySlide(1215)?.map((item) => (
									<SwiperSlide
										key={`sponsorship-empty-${item}`}
										className="blank-space"
									>
										<div className="blank" />
									</SwiperSlide>
								))}
								{sponsorshipDetail && (
									<SwiperSlide id="sponsorship-detail">
										<SponsorshipDetail mobile={mobile} />
									</SwiperSlide>
								)}
								{!mobile ? (
									<>
										{/* PC 용 */}
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
										<SwiperSlide className="blank-space">
											<div className="blank" />
										</SwiperSlide>
									</>
								) : (
									sponsorshipDetail && (
										<>
											{/* 모바일 용 */}
											{makeEmptySlide(2500).map((item) => (
												<SwiperSlide
													key={`sponsorship-detail-${item}`}
													className="blank-space"
												>
													<div className="blank" />
												</SwiperSlide>
											))}
										</>
									)
								)}
							</>
						)}
					</LastStyledSwiper>
				),
			},
		]);
	}, [mobile, sponsorshipDetail, secondSwiperCurrentPage]);

	return (
		<>
			<Header
				swiper={swiperRef}
				firstSwiperCurrentPage={firstSwiperCurrentPage}
			/>
			<MainStyled id="main-container">
				<StyledSwiper
					direction="horizontal"
					modules={[Navigation, Pagination, Scrollbar, Mousewheel]}
					speed={800}
					navigation={{
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next',
					}}
					allowTouchMove={mobile}
					touchRatio={mobile ? 1 : 0}
					autoHeight
					mousewheel={{ enabled: true, sensitivity: 0.5 }}
					onSlideChange={(e) => setFirstSwiperCurrentPage(e.activeIndex)}
					onSwiper={(swiper) => (swiperRef.current = swiper)}
					onTransitionEnd={(e) => {
						if (mobile && e.isEnd && e.activeIndex === 5) {
							changeActiveSwiperTo(4);
						} else if (e.activeIndex === 3) {
							changeActiveSwiperTo(2);
						} else if (e.activeIndex === 4) {
							changeActiveSwiperTo(3);
						}
					}}
				>
					{/* {!mobile && ( */}
					<SwiperButtonPrev
						firstSwiperCurrentPage={firstSwiperCurrentPage}
						secondSwiperRef={secondSwiperRef}
						changeActiveSwiperTo={changeActiveSwiperTo}
						lastSwiperRef={lastSwiperRef}
					/>
					{/* )} */}
					{sections.map(({ id, element }) => (
						<SwiperSlide key={id}>{element}</SwiperSlide>
					))}
					{/* {!mobile && ( */}
					<SwiperButtonNext
						firstSwiperCurrentPage={firstSwiperCurrentPage}
						secondSwiperRef={secondSwiperRef}
						lastSwiperRef={lastSwiperRef}
						changeActiveSwiperTo={changeActiveSwiperTo}
					/>
					{/* )} */}
					<SwiperPaginationProgressBar
						firstSwiperCurrentPage={firstSwiperCurrentPage}
						mobile={mobile}
					/>
					{/* </Swiper> */}
				</StyledSwiper>
				<div className={`alert${toastVisible ? ' visible' : ' hidden'}`}>
					링크가 복사되었습니다 !
				</div>
			</MainStyled>
		</>
	);
};

export default App;
