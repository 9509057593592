import blueStar from '../../assets/svgs/ic_p7_star_blue.svg';
import front from '../../assets/svgs/ic_p7_front.svg';
import dcamp from '../../assets/svgs/ic_p7_dcamp.svg';
import dcampMobile from '../../assets/svgs/ic_p7_dcamp_m.svg';
import sk from '../../assets/svgs/ic_p7_sk.svg';
import skMobile from '../../assets/svgs/ic_p7_sk_m.svg';
import softsquared from '../../assets/svgs/ic_p7_sq.svg';
import softsquaredMobile from '../../assets/svgs/ic_p7_sq_m.svg';
import whiteStar from '../../assets/svgs/ic_p7_star_white.svg';

export default function Sponsorship({
	swiperRef,
	mobile,
	lastSwiperRef,
	changeActiveSwiperTo,
	setSponsorshipDetail,
}) {
	return (
		<section className="sponsorship">
			<div className="top" />
			<div className="left">
				<img className="blueStar" src={blueStar} alt="star" />
				<div className="text">
					<div className="title">
						CORPORATE
						<br />
						SPONSORSHIP
					</div>
					<div className="subtitle">너디너리 데모데이 후원기업</div>
				</div>
			</div>
			<div className="right">
				<div className="main-sponsor">MAIN SPONSOR</div>
				<div className="sponsorships">
					<div className="dcamp">
						<div>
							{mobile ? (
								<img src={dcampMobile} alt="d.camp" />
							) : (
								<img src={dcamp} alt="d.camp" />
							)}
						</div>
						<div>은행권청년창업재단 D.CAMP</div>
						<div>공간 후원(프론트원) / 상금 후원</div>
					</div>
					<div className="sk">
						<div>
							{mobile ? (
								<img src={skMobile} alt="sk planet t academy" />
							) : (
								<img src={sk} alt="sk planet t academy" />
							)}
						</div>
						<div>SK Planet T Academy</div>
						<div>운영 지원</div>
					</div>
					<div className="softsquared">
						<div>
							{mobile ? (
								<img src={softsquaredMobile} alt="softsquared" />
							) : (
								<img src={softsquared} alt="softsquared" />
							)}
						</div>
						<div>(주) 소프트스퀘어드</div>
						<div>운영비 일부 후원</div>
					</div>
				</div>
				<div>
					<div className="left">
						<div>
							WE'RE
							<br />
							LOOKING FOR A<br />
							SPONSOR.
						</div>
						<div>
							+++
							<img className="whiteStar" src={whiteStar} alt="star" />
						</div>
					</div>
					<div className="right">
						<div>
							후원을 통해
							<br />
							세상을 바꾸는 청년들을 응원할
							<br />
							기업들을 찾습니다!
						</div>
						<button
							type="button"
							onClick={() => {
								setSponsorshipDetail(true);
								changeActiveSwiperTo(4);
								setTimeout(() => lastSwiperRef.current?.slideNext(), 100);
							}}
						>
							기업 후원 안내
						</button>
					</div>
				</div>
			</div>
			<button
				type="button"
				className="front"
				onClick={() => {
					swiperRef.slideTo(1);
					changeActiveSwiperTo(1);
				}}
			>
				<img src={front} alt="to the front" />
				<div>TO THE FRONT</div>
			</button>
		</section>
	);
}
