import prev from '../../assets/svgs/ic_pageleft.svg';

export default function SwiperButtonPrev({
	changeActiveSwiperTo,
	secondSwiperRef,
	lastSwiperRef,
}) {
	return (
		<img
			src={prev}
			className="swiper-button-prev"
			onClick={() => {
				secondSwiperRef.current.slideTo(0);
				lastSwiperRef.current.slideTo(0);
				changeActiveSwiperTo(1);
			}}
		/>
	);
}
