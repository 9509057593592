import iconStar from '../../assets/svgs/ic_staryellow.svg';
import imageLogo01 from '../../assets/images/img_logo01.png';
import imageLogo02 from '../../assets/images/img_logo02.png';
import imageLogo03 from '../../assets/images/img_logo03.png';
import imageLogo04 from '../../assets/images/img_logo04.png';
import imageLogo05 from '../../assets/images/img_logo05.png';
import imageLogo06 from '../../assets/images/img_logo06.png';
import imageLogo07 from '../../assets/images/img_logo07.png';
import imageLogo08 from '../../assets/images/img_logo08.png';
import imageLogo09 from '../../assets/images/img_logo09.png';
import imageLogo10 from '../../assets/images/img_logo10.png';
import imageLogo11 from '../../assets/images/img_logo11.png';
import imageLogoEO from '../../assets/images/img_logo_eo.png';
import imageLogoDisquiet from '../../assets/images/img_logo_disquiet.png';
// import imageLogoPathway from '../../assets/images/img_logo_pathway.png';
import imageLogoJobaCafe from '../../assets/images/img_logo_jobacafe.png';
import imageLogoHanbit from '../../assets/images/img_logo_hanbit.png';

const companies = [
	{
		tag: '프론트원 1F',
		icon: imageLogo03,
		name: '그릿지',
		desc: 'IT 프로젝트 개발팀 빌딩 플랫폼',
		link: 'https://gridge.co.kr',
	},
	{
		tag: '프론트원 1F',
		icon: imageLogo07,
		name: '뤼튼테크놀로지스',
		extraSmallDesc: true,
		desc: '한국어와 일본어를 가장 잘하는 생성 AI 서비스를\n제공하는 글로벌 생성 AI 플랫폼 기업',
		link: 'https://wrtn.ai',
	},
	{
		tag: '프론트원 1F',
		icon: imageLogo01,
		name: '쿼타랩',
		desc: '증권 관리 솔루션의 표준, 쿼타북',
		link: 'https://quotabook.com',
	},
	{
		tag: '프론트원 1F',
		icon: imageLogoEO,
		name: 'EO STUDIO',
		desc: 'Entrepreneurship & Opportunity\n기업가 정신과 기회를 널리 알립니다',
		link: 'https://eopla.net',
	},
	{
		icon: imageLogoDisquiet,
		name: '디스콰이엇',
		desc: '메이커들의 인사이트 교류 커뮤니티',
		link: 'https://disquiet.io',
	},
	{
		tag: '프론트원 1F',
		icon: imageLogo06,
		name: '이십사점오',
		smallDesc: true,
		desc: 'Z세대 커리어 관리 플랫폼 <슥삭>과\n가장 빠른 신입 인재 매칭 서비스\n<슈퍼패스>를 운영',
		link: 'https://www.ssgsag.kr',
	},
	{
		tag: '프론트원 B1F',
		icon: imageLogo05,
		name: '커리어멘토스',
		desc: '성장퀴즈서비스 인빈서블X',
		link: 'https://camen.co.kr',
	},
	{
		icon: imageLogo08,
		name: '도서출판 길벗',
		desc: '컴퓨터 서적을 전문으로 출판하는\n실용서 출판사',
		link: 'https://www.gilbut.co.kr',
	},
	{
		icon: imageLogoHanbit,
		name: '한빛미디어',
		desc: 'IT 활용 분야의 책을 출간하는\nIT 전문 출판사',
		link: 'https://www.hanbit.co.kr/media/channel/list.html?page=7&cate_cd=&keyWord=&searchKey=',
	},
	{
		tag: '프론트원 1F',
		icon: imageLogo04,
		name: 'ALAM',
		smallDesc: true,
		desc: '펜 모양 VR 번들 컨트롤러 프로젝트 진행중',
		link: 'https://alamvr.com',
	},
	{
		tag: '프론트원 B1F',
		icon: imageLogoJobaCafe,
		name: '자바카페',
		smallDesc: true,
		desc: '개발자들 사이의 네트워크를 구축하여\n서로 발전하고 친목을 도모하는\n오프라인 커뮤니티',
		link: 'https://www.facebook.com/groups/javacafe/?locale=ko_KR',
	},
	{
		tag: '프론트원 B1F',
		icon: imageLogo02,
		name: 'archetype',
		desc: 'IT 작업자를 위한 패션브랜드',
		link: 'https://archetypeonline.shop',
	},
	{
		icon: imageLogo09,
		name: '인천창조경제혁신센터',
		desc: '엑셀러레이터',
		link: 'https://ccei.creativekorea.or.kr/incheon/',
	},
	{
		icon: imageLogo10,
		name: '씨앤벤처 파트너스',
		desc: '엑셀러레이터',
		link: 'http://cnvpartners.co.kr',
	},
	{
		icon: imageLogo11,
		name: '현대기술투자',
		desc: '벤처캐피탈',
		link: 'http://hvic.co.kr/kor/',
	},
];

export default function SponsoringCompanies() {
	return (
		<section className="sponsoring-companies">
			<div className="head">
				<img src={iconStar} alt="" />
				<div className="title">
					SPONSORING
					<br />
					COMPANY
				</div>
				<div className="sub-title">너디너리 데모데이 참여후원사</div>
			</div>
			<div className="companies">
				{companies.map(
					(
						{ tag, icon, name, smallDesc, extraSmallDesc, desc, link },
						index
					) => (
						<div key={index} className="company">
							<div
								className="icon"
								onClick={() => {
									if (link) window.open(link, '_blank');
									else return;
								}}
							>
								{tag && (
									<div
										className={`tag${
											tag === '프론트원 1F' ? ' blue' : ' yellow'
										}`}
									>
										{tag}
									</div>
								)}
								<img src={icon} alt="" />
							</div>
							<div className="name">{name}</div>
							<div
								className={`desc${
									smallDesc ? ' smallDesc' : extraSmallDesc ? ' xsmallDesc' : ''
								}`}
							>
								{desc}
							</div>
						</div>
					)
				)}
			</div>
		</section>
	);
}
