import logo from '../../assets/svgs/p5_neordinary_logo.svg';
import kakao from '../../assets/svgs/ic_p5_kakao.svg';
import kakaoMobile from '../../assets/svgs/ic_p5_kakao_m.svg';
import link from '../../assets/svgs/ic_p5_link.svg';
import linkMobile from '../../assets/svgs/ic_p5_link_m.svg';
import { useEffect } from 'react';

const KAKAO_APP_KEY = '0ea3df52ac4b3ae823d76d3a552399ad';

export default function Share({ mobile, setToastVisible }) {
	const kakaoShare = async () => {
		Kakao.Share.sendDefault({
			objectType: 'text',
			text: '2023 너디너리 데모데이 - 세상의 모든 작업자들을 위한 NE(O)RDINARY 축제가 열립니다 🎉',
			link: {
				mobileWebUrl: 'https://bit.ly/3NKbVA',
				webUrl: 'https://bit.ly/3NKbVA',
			},
		});
	};

	const shareLink = () => {
		window.navigator.clipboard.writeText('https://bit.ly/3NKbVA1').then(() => {
			setToastVisible(true);
			setTimeout(() => setToastVisible(false), 5000);
		});
	};

	useEffect(() => {
		if (!Kakao.isInitialized()) Kakao.init(KAKAO_APP_KEY);
	}, []);

	return (
		<section className="share">
			<img src={logo} alt="너디너리" />
			<div>
				좋은 건 나눌수록 두 배가 되니까!
				<br />
				친구, 동료에게 너디너리 데모데이를 알려주세요 :)
			</div>
			<div>
				<button type="button" className="kakao" onClick={kakaoShare}>
					{mobile ? (
						<img src={kakaoMobile} alt="kakao" />
					) : (
						<img src={kakao} alt="kakao" />
					)}
					카카오톡 공유하기
				</button>
				<button
					type="button"
					className="link"
					onClick={() => {
						if (window.navigator.share) {
							window.navigator
								.share({
									title: '2023 너디너리 데모데이',
									text: '세상의 모든 작업자들을 위한 NE(O)RDINARY 축제가 열립니다 🎉',
									url: 'https://bit.ly/3NKbVA1',
								})
								.catch(() => null);
						} else shareLink();
					}}
				>
					{mobile ? (
						<img src={linkMobile} alt="link" />
					) : (
						<img src={link} alt="link" />
					)}
					링크로 공유하기
				</button>
			</div>
		</section>
	);
}
