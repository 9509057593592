import { useState } from 'react';
import { styled } from 'styled-components';

export default function PresenterCard({
	mobile,
	tag,
	name,
	images,
	image,
	belongTo,
	startAt,
	endAt,
	content,
	smallText,
	multiline,
	careers,
}) {
	const [visible, setVisible] = useState(false);

	return (
		<CardStyled
			visible={visible || undefined}
			onMouseEnter={() => (!mobile ? setVisible(true) : null)}
			onMouseLeave={() => (!mobile ? setVisible(false) : null)}
			onClick={() => mobile && setVisible((prev) => !prev)}
		>
			<div className="background">
				<div className="tag">{tag}</div>
				{images ? (
					<div className="images">
						{images.map((image, index) => (
							<img key={index} src={image} alt={name} />
						))}
					</div>
				) : (
					<div className="image">
						<img src={image} alt={name} />
					</div>
				)}
				<div className="name">{name}</div>
				<div className="belongTo">{belongTo}</div>
				<div className="time">
					<span className="startAt">{startAt}</span>
					<span>-</span>
					<span className="endAt">{endAt}</span>
				</div>
				<div
					className={`content${smallText ? ' smallText' : ''}${
						multiline ? ' multiline' : ''
					}`}
				>
					{'“ '}
					{content}
					{' ”'}
				</div>
			</div>
			<div className="overlay">
				<div className="">연사이력</div>
				<ul className="careers">
					{careers.map((career, index) => {
						if (career?.name)
							return (
								<li className="careers-each" key={index}>
									<div className="name">{career.name}</div>
									<ul>
										{career.career.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</li>
							);
						else
							return (
								<li className="career" key={index}>
									{career}
								</li>
							);
					})}
				</ul>
			</div>
		</CardStyled>
	);
}

const CardStyled = styled.div`
	margin: 0 auto;
	width: 37.4rem;
	height: 57rem;
	background: linear-gradient(
		180deg,
		#0085ff 0%,
		rgba(225, 228, 255, 0.36) 60.94%,
		rgba(170, 177, 247, 0.32) 99.99%,
		rgba(255, 255, 255, 0) 100%
	);
	position: relative;
	border-radius: 2.3rem;
	padding: 2rem;
	color: white;
	box-sizing: border-box;
	overflow: hidden;
	white-space: pre-line;

	.background {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;

		& > * {
			width: 100%;
			text-align: center;
		}

		.tag {
			width: max-content;
			display: flex;
			padding: 0.75rem 1.5rem;
			font-size: 2rem;
			line-height: 1.737;
			font-weight: 700;
			color: white;
			background-color: var(--color-sub-blue);
			border-radius: 3.2rem;
			border: 0.1rem solid var(--color-main-white);
			margin-bottom: 1.7rem;
		}

		.images {
			display: flex;
			justify-content: center;
			margin-top: 3.4rem;
			margin-bottom: 6.1rem;

			img {
				width: 17rem;
				height: 17rem;
				border-radius: 100%;

				&:first-of-type {
					margin-right: -5.9rem;
					z-index: 0;
				}
			}
		}

		.image {
			width: 100%;
			margin-bottom: 2.6rem;

			img {
				display: block;
				width: 24rem;
				height: 24rem;
				margin: 0 auto;
				border-radius: 100%;
			}
		}

		.name {
			font-size: 2.8rem;
			font-weight: 700;
			letter-spacing: -0.065em;
			margin-bottom: 1.6rem;
		}

		.belongTo {
			font-size: 2.2rem;
			font-weight: 700;
			letter-spacing: -0.065em;
			margin-bottom: 0.6rem;
		}

		.time {
			span {
				font-size: 2rem;
				font-weight: 500;
				letter-spacing: -0.065em;
			}
		}

		.content {
			font-size: 2.4rem;
			font-weight: 700;
			letter-spacing: -0.065em;
			margin-top: 2.7rem;

			&.smallText {
				font-size: 2rem;
				margin-top: 3rem;

				&.multiline {
					margin-top: 1.7rem;
				}
			}
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		backdrop-filter: blur(2rem);
		-webkit-backdrop-filter: blur(2rem);
		opacity: ${(props) => (props.visible ? 1 : 0)};
		padding: 6.8rem 2rem 0;
		background-color: #10174160;
		transition: all 0.2s ease-in-out;

		& > div {
			font-size: 2.8rem;
			font-weight: 700;
			letter-spacing: -0.065em;
			margin-bottom: 13.4rem;
		}

		.careers {
			list-style: none;

			.career {
				font-size: 1.8rem;
				font-weight: 500;
				line-height: 1.867;
				letter-spacing: -0.065em;
				list-style: none;

				&:before {
					content: '-';
					margin-right: 0.5rem;
				}
			}

			.careers-each {
				font-weight: 500;
				line-height: 1.867;
				letter-spacing: -0.065em;
				margin-bottom: 3.6rem;

				div {
					font-size: 1.8rem;
					font-weight: 700;
				}

				ul li {
					font-size: 1.8rem;
					list-style: none;
					letter-spacing: -0.065em;

					&:before {
						content: '-';
						margin-right: 0.5rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1279px) {
		width: 100%;
		max-width: 32rem;
		margin: 0 auto;
		height: 39.1rem;
		padding: 1.5rem;

		.background {
			.tag {
				padding: 0.65rem 1.2rem;
				font-size: 1.4rem;
				line-height: 1.737;
				margin-bottom: 1rem;
			}

			.images {
				margin: 0;
				margin-bottom: 2.488rem;

				img {
					width: 14.512rem;
					height: 14.512rem;

					&:first-of-type {
						margin-right: -5.12rem;
					}
				}
			}

			.image {
				margin-bottom: 1.9rem;

				img {
					width: 15.4rem;
					height: 15.4rem;
				}
			}

			.name {
				font-size: 1.6rem;
				margin-bottom: 1.3rem;
			}

			.belongTo {
				margin-bottom: 0.2rem;
			}

			.belongTo,
			.time > span {
				font-size: 1.4rem;
			}

			.content {
				margin-top: 1.4rem;
				font-size: 1.8rem;

				&.smallText {
					margin-top: 1.4rem;
					font-size: 1.6rem;

					&.multiline {
						margin-top: 1.4rem;
					}
				}
			}
		}

		.overlay {
			padding: 3.7rem 2rem;

			& > div {
				font-size: 2.4rem;
				margin-bottom: 9.5rem;
			}

			.careers {
				.career {
					font-size: 1.4rem;
					line-height: 1.867;
				}

				.careers-each {
					margin-bottom: 3.6rem;

					div {
						font-size: 1.4rem;
						line-height: 1.867;
					}

					ul li {
						font-size: 1.4rem;
						line-height: 1.867;
					}
				}
			}
		}
	}
`;
