import star from '../../assets/svgs/ic_p2_star.svg';
import check from '../../assets/svgs/p2_check.svg';

export default function Introduction() {
	return (
		<section className="introduction">
			<div className="left">
				COME HERE
				<br />
				AND GET
				<br />
				BIGGEST
				<br />
				NETWORK
				<img className="star" src={star} alt="star" />
			</div>
			<div className="right">
				<div className="title">
					<img className="check" src={check} alt="check" />
					너디너리 데모데이는
					<br />
					IT작업자들을 위한
					<br />
					축제입니다.
				</div>
				<div className="contents">
					대학생들에게는 <strong>스타트업에 대한 관심</strong>을,
					<br />
					예비/초기 창업자에게는 <strong>고객유치와 서비스 홍보</strong>를,
					<br />
					기업 관계자에게는 <strong>우수한 인재를 탐색할 수 있는 장</strong>을
					제공합니다.
				</div>
				<div className="contents">
					나아가 데모데이에 방문하는 모든 참여자가{' '}
					<strong>네트워킹을 통해 인사이트</strong>를
					<br />
					넓힐 수 있도록 준비했습니다.
				</div>
			</div>
		</section>
	);
}
