import star from '../../assets/svgs/ic_p8_star.svg';
import sponsorshipGrade from '../../assets/images/img_p8_01.png';
import boothRed from '../../assets/images/img_p8_02.png';
import boothBlue from '../../assets/images/img_p8_03.png';
import boothYellow from '../../assets/images/img_p8_04.png';
import sponsorshipPlan from '../../assets/images/img_p8_05.png';
import recruitmentProcess from '../../assets/images/img_p8_06.png';

export default function SponsorshipDetail({ mobile }) {
	return (
		<section className={`sponsorship-detail`}>
			<div className="background-typo">
				being
				<br />
				neordi
				<br />
				nary,
			</div>
			<div className="guide">
				<div className="left">
					<img src={star} alt="star" />
					IT 작업자들을 위한 축제,
					<br />
					너디너리 데모데이에
					<br />
					참여하세요.
				</div>
				<div className="right">
					<div className="title">
						기업 부스 및<br />
						후원 프로세스 안내
					</div>
					<div className="desc">
						서비스 홍보, 인재 채용 등 희망하는 형태로 기업 부스를 진행해
						너디너리
						{!mobile && <br />} 데모데이 참여자 분들을 만나보세요!
					</div>
					<div className="buttons">
						<div>기업 후원이 종료되었습니다.</div>
						<button type="button" onClick={() => {}}>
							기업 후원 신청하기
						</button>
					</div>
				</div>
			</div>
			<div className="section sponsorship-grade">
				<div className="title">
					후원등급
					<br />
					안내
				</div>
				<img
					src={sponsorshipGrade}
					alt="후원 등급 안내"
					onClick={() =>
						(window.location.href = 'mailto:aien.lee@softsquared.com')
					}
				/>
			</div>
			<div className="section booth-grade">
				<div className="title">
					부스 등급
					<br />
					안내
				</div>
				<img src={boothRed} alt="red 부스" />
				<img src={boothBlue} alt="blue 부스" />
				<img src={boothYellow} alt="yellow 부스" />
			</div>
			<div className="section sponsorship-plan">
				<div className="title">
					후원금
					<br />
					사용계획
				</div>
				<img src={sponsorshipPlan} alt="후원금 사용계획" />
			</div>
			<div className="section recruitment-process">
				<div className="title">
					기업 후원
					<br />
					모집 프로세스
				</div>
				<img src={recruitmentProcess} alt="기업 후원 모집 프로세스" />
			</div>
			<div className="buttons">
				<div>기업 후원이 종료되었습니다.</div>
				<button type="button" onClick={() => {}}>
					기업 후원 신청하기
				</button>
			</div>
		</section>
	);
}
