import next from '../../assets/svgs/ic_pageright.svg';

export default function SwiperButtonNext({
	changeActiveSwiperTo,
	secondSwiperRef,
	lastSwiperRef,
}) {
	return (
		<img
			src={next}
			className="swiper-button-next"
			onClick={() => {
				changeActiveSwiperTo(1);
				secondSwiperRef.current.slideTo(0);
				lastSwiperRef.current?.mousewheel.disable();
			}}
		/>
	);
}
