import star from '../../assets/svgs/ic_p6_star.svg';
import crowd from '../../assets/images/img_p6_people.png';
import crowdMobile from '../../assets/images/img_p6_people_m.png';
import arrow from '../../assets/svgs/ic_p6_arrow.svg';
import arrowMobile from '../../assets/svgs/ic_p6_arrow_m.svg';

export default function FAQ({ mobile }) {
	return (
		<section className="faq">
			{mobile ? (
				<img className="arrow" src={arrowMobile} alt="arrow" />
			) : (
				<img className="arrow" src={arrow} alt="arrow" />
			)}
			<div className="left">
				<div>FAQ</div>
				<div>자주 묻는 질문들</div>
				<img src={star} alt="star" />
			</div>
			<div className="right">
				<div className="line" />
				<div className="set">
					<div className="question">
						Q. 너디너리 데모데이에 어떻게 갈 수 있나요?
					</div>
					<div className="answer answer1">
						우측 상단의 사전신청 버튼을 눌러 신청폼을 제출해주세요!
					</div>
				</div>
				<div className="line" />
				<div className="set">
					<div className="question">
						Q. 사전신청을 하지 않으면 참여할 수 없나요?
					</div>
					<div className="answer answer2">
						현장 입장도 가능하지만, 행사 공식 기념품은 사전 신청자에 한해
						준비됩니다.
					</div>
				</div>
				<div className="line" />
				<div className="set">
					<div className="question">Q. 주차가 가능한가요?</div>
					<div className="answer answer3">
						행사 참여자 분들을 위한 주차 공간이 별도로 마련되어 있지 않습니다.
						{!mobile && <br />}
						대중교통 이용을 권장드립니다.
					</div>
				</div>
			</div>
			{mobile ? (
				<img src={crowdMobile} alt="crowd" />
			) : (
				<img src={crowd} alt="crowd" />
			)}
		</section>
	);
}
