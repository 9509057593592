export default function SwiperPaginationProgressBar({
	firstSwiperCurrentPage,
	mobile,
}) {
	return (
		<div
			className={`swiper-pagination-progressbar ${
				firstSwiperCurrentPage === 0 ? 'inactive' : ''
			}`}
		>
			<div
				className={`swiper-pagination-progressbar-fill`}
				style={{
					width: `${
						(mobile ? 5.33333333333 : 21) *
						(firstSwiperCurrentPage <= 2 ? 1 : firstSwiperCurrentPage - 1)
					}rem`,
				}}
			/>
		</div>
	);
}
