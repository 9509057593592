import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const MainStyled = styled.main`
	position: relative;
	margin-top: 16.4rem;
	height: 100%;

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 50;
		cursor: pointer;
	}

	.swiper-button-prev {
		left: 7rem;
	}

	.swiper-button-next {
		right: 7rem;
	}

	section.sponsorship-detail {
		color: var(--color-main-white);
		display: flex;
		flex-direction: column;
		position: relative;
		height: max-content;
		transition: all 0.2s ease-in-out;

		&.visible {
			opacity: 1;
			visibility: visible;
			max-height: max-content;
		}

		&.hidden {
			opacity: 0;
			display: none;
		}

		.background-typo {
			position: absolute;
			top: 3.7rem;
			left: 17.9rem;
			font-family: 'Tango Sans';
			font-size: 16rem;
			line-height: 0.935;
			letter-spacing: 0.85rem;
			font-weight: 700;
			color: var(--color-sub-blue);
			opacity: 30%;
		}

		.guide {
			padding-top: 19.5rem;
			display: flex;
			justify-content: space-between;
			margin-bottom: 13rem;
			column-gap: 5rem;

			.left {
				position: relative;
				margin-left: auto;
				font-size: 4.8rem;
				font-weight: 700;
				line-height: 1.33;
				letter-spacing: -0.04rem;

				& > img {
					position: absolute;
					top: -9.5rem;
				}
			}

			.right {
				margin-right: auto;

				.title {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 1.33;
					letter-spacing: -0.04rem;
					margin-bottom: 1.9rem;
				}

				.desc {
					font-size: 2.4rem;
					line-height: 1.61;
					letter-spacing: -0.04rem;
					margin-bottom: 3.2rem;
				}
			}
		}

		.section {
			padding-top: 5.8rem;
			padding-bottom: 9rem;
			margin: 0 auto;
			border-top: 0.3rem solid #cccccc;
			display: flex;
			flex-direction: column;

			&:last-of-type {
				padding-bottom: 39rem;
			}

			.title {
				width: 100%;
				font-size: 3.8rem;
				font-weight: 700;
				line-height: 1.33;
				letter-spacing: -0.04rem;
				margin-bottom: 4.7rem;
			}
		}

		.buttons {
			display: flex;
			column-gap: 3.1rem;

			* {
				padding: 2.15rem 1.75rem;
				border-radius: 1.5rem;
				font-size: 2.4rem;
				letter-spacing: -0.045rem;
				font-weight: 700;
			}

			*:first-child {
				background-color: #3f3f3f;
				color: var(--color-main-white);
			}

			*:last-child {
				background-color: var(--color-sub-yellow);
				color: var(--color-main-black);
			}
		}

		& > .buttons {
			margin: 0 auto;
			justify-content: center;
		}
	}

	& > .alert {
		position: absolute;
		top: 4rem;
		left: 50%;
		padding: 2rem 3rem;
		background-color: #2f2f2f;
		color: var(--color-main-white);
		font-size: 2.4rem;
		width: max-content;
		height: max-content;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5rem;
		transition: all 0.2s ease-in-out;

		&.visible {
			opacity: 1;
			z-index: 100;
			transform: translateX(-50%) translateY(0);
		}

		&.hidden {
			opacity: 0;
			z-index: -100;
			transform: translateX(-50%) translateY(-1rem);
		}
	}

	@media screen and (max-width: 1279px) {
		width: 100%;
		margin-top: 0;

		.swiper-button-prev,
		.swiper-button-next,
		.prev,
		.next {
			transform: none;
			width: 3.1rem;
		}

		.swiper-button-prev,
		.prev {
			left: 0.9rem;
		}

		.swiper-button-next,
		.next {
			right: 0.9rem;
		}

		& > .alert {
			padding: 1.5rem 2rem;
			font-size: 1.4rem;
			top: 12rem;
		}
	}
`;

export const StyledSwiper = styled(Swiper)`
	width: 100vw;

	.swiper-button-prev,
	.swiper-button-next {
		visibility: visible;
		opacity: 1;
		transition: all 0.2s ease-in-out;

		&::after {
			content: '';
		}

		&.swiper-button-disabled {
			visibility: hidden;
			opacity: 0;
		}
	}

	.swiper-wrapper {
		display: flex;

		.swiper-slide {
			height: 91.6rem;

			& > section {
				min-width: 100vw;
				color: var(--color-main-white);
				display: flex;

				&.main {
					.video {
						width: auto;
						height: calc(100vh - 164px);
						margin: 0 auto;
					}
				}

				&.introduction {
					margin-top: 15.5rem;

					.left {
						margin-left: 17.9rem;
						font-family: 'Tango Sans';
						font-weight: 700;
						font-size: 10.8rem;
						margin-right: 7.1rem;
						position: relative;
						line-height: 1.16;
						letter-spacing: 0.35rem;

						.star {
							position: absolute;
							top: -4.4rem;
							left: 8.2rem;
						}
					}

					.right {
						.title {
							font-size: 5.2rem;
							font-weight: 700;
							line-height: 1.33;
							letter-spacing: -0.04rem;
							margin-bottom: 4.6rem;
							position: relative;

							.check {
								position: absolute;
								top: 5.2rem;
								left: -3.2rem;
							}
						}

						.contents {
							line-height: 1.61;
							margin-bottom: 3.1rem;
							font-size: 2.4rem;

							& > strong {
								font-size: inherit;
							}
						}
					}
				}

				&.booth {
					display: flex;
					flex-direction: column;
					align-items: center;
					height: calc(100vh - 164px);
					overflow-y: scroll;
					position: relative;

					.booth-box {
						margin-top: 6.7rem;
						background-color: var(--color-main-black);
						width: 156.4rem;
						height: 68.2rem;
						display: flex;
						border: 0.1rem solid var(--color-main-white);

						.booth-box-left {
							width: 78.3rem;
							display: flex;
							flex-direction: column;
							border-right: 0.1rem solid var(--color-main-white);
							position: relative;

							.booth-box-left-title {
								position: absolute;
								top: 1.5rem;
								left: 2.2rem;
								font-family: 'Tango Sans';
								font-weight: bold;
								font-size: 3.4rem;
								letter-spacing: 0.08em;
								line-height: 1.33;
							}

							.booth-box-left-image {
								padding: 6.5rem 13.15rem 3.7rem;
							}
						}

						.booth-box-right {
							width: 78.2rem;
							display: flex;
							flex-direction: column;

							.booth-box-right-section {
								display: flex;
								flex-direction: column;
								justify-content: center;
								padding: 2.7rem 0;
								padding-left: 3.6rem;

								& > * {
									line-height: 1.33;
									letter-spacing: -0.04em;
									font-size: 2.4rem;
								}

								&:not(:last-of-type) {
									border-bottom: 0.1rem solid var(--color-main-white);
								}

								&:first-child {
									.block {
										background-color: var(--color-sub-red);
									}
								}

								&:nth-child(2) {
									.block {
										background-color: var(--color-sub-blue);
									}
								}

								&:last-child {
									.block {
										background-color: var(--color-sub-yellow);
										color: var(--color-main-black);
									}
								}

								.block {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 10.5rem;
									height: 5.4rem;
									font-size: 3.4rem;
									font-family: 'Tango Sans';
									font-weight: 700;
									letter-spacing: 0.08em;
									border-radius: 3.2rem;
									border: 0.1rem solid var(--color-main-white);
									margin-bottom: 1.4rem;
								}

								.title {
									font-weight: bold;
									margin-bottom: 0.9rem;
								}

								.desc {
									font-size: 2rem;
									line-height: 1.61;
								}
							}
						}
					}
				}

				&.presenters {
					display: block;
					height: 91.6rem;
					position: relative;

					.top {
						position: absolute;
						top: 0;
						left: 0;
						width: 100vw;
						height: 0.01rem;
					}

					.video-box {
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						height: 100%;
						overflow: hidden;

						.video {
							width: 100vw;
							object-fit: cover;
							opacity: 0.4;
							z-index: -1;
						}

						& > div {
							width: 100vw;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							position: absolute;
							text-align: center;
							margin-top: 9.8rem;

							& > img {
								margin-bottom: 3.5rem;
							}

							& > div:first-of-type {
								margin-bottom: 1.5rem;
								font-size: 4.8rem;
								line-height: 1.33;
								letter-spacing: -0.04rem;
								font-weight: 700;
							}

							& > div:last-of-type {
								display: flex;
								flex-direction: column;
								margin-bottom: 4rem;
								font-size: 2.4rem;
								line-height: 1.61;
								letter-spacing: -0.04rem;
							}

							& > button {
								padding: 3rem 3.6rem;
								border-radius: 1.9rem;
								font-size: 3.2rem;
								font-weight: 700;
								letter-spacing: -0.045rem;
							}
						}
					}
				}

				&.timetable {
					width: 100vw;
					display: flex;
					flex-direction: column;
					box-sizing: border-box;

					.head {
						padding: 4rem 0;
						border-bottom: 0.3rem solid var(--color-main-white);
						margin: 0 18rem;

						img {
							margin-bottom: 1rem;
						}

						.title {
							font-family: 'Tango Sans';
							font-size: 5.8rem;
							font-weight: 700;
							line-height: 0.948;
							margin-bottom: 1.7rem;
						}

						.sub-title {
							font-size: 3.2rem;
							font-weight: 700;
							letter-spacing: -0.065em;
						}
					}

					.body {
						margin: 0 18rem;
						position: relative;

						.schedule {
							padding: 4rem 0;

							&:not(:last-of-type) {
								border-bottom: 0.3rem solid var(--color-main-white);
							}

							.date {
								font-family: 'Tango Sans';
								font-size: 4.8rem;
								font-weight: 700;
								line-height: 1.108;
								margin-bottom: 4rem;
							}

							.presenters {
								width: 100%;
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								margin: 0 auto;
								row-gap: 10rem;
							}
						}
					}
				}

				&.share {
					display: flex;
					flex-direction: column;
					align-items: center;

					& > img {
						width: 64.6rem;
						margin-top: 13.9rem;
						margin-bottom: 2.3rem;
					}

					& > div:first-of-type {
						font-size: 3.8rem;
						font-weight: 700;
						line-height: 1.33;
						letter-spacing: -0.04rem;
						text-align: center;
						margin-bottom: 4rem;
					}

					& > div:last-of-type {
						display: flex;
						gap: 3.2rem;

						& > button {
							width: 36.9rem;
							height: 10rem;
							border-radius: 1.9rem;
							font-size: 3.2rem;
							font-weight: 700;
							letter-spacing: -0.045rem;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.kakao {
							background-color: var(--color-sub-yellow);
							color: var(--color-main-black);
							gap: 0.8rem;
						}

						.link {
							background-color: var(--color-sub-light-gray);
							color: var(--color-main-black);
							gap: 1.2rem;
						}
					}
				}

				&.faq {
					padding-top: 17.1rem;
					height: 91.6rem;
					box-sizing: border-box;
					display: flex;
					position: relative;

					.left {
						margin-right: 18.5rem;
						margin-left: 34rem;
						position: relative;

						& > div:first-of-type {
							font-family: 'Tango Sans';
							font-weight: 700;
							font-size: 13rem;
							line-height: 0.948;
							color: var(--color-sub-blue);
							margin-bottom: 2.1rem;
						}

						& > div:last-of-type {
							font-size: 3.4rem;
							line-height: 1.33;
							letter-spacing: -0.04rem;
							font-weight: 700;
						}

						& > img {
							position: absolute;
							top: -9.7rem;
						}
					}

					.right {
						line-height: 1.33;
						letter-spacing: -0.04rem;

						.line {
							width: 59.7rem;
							height: 0.3rem;
							background-color: #cccccc;
							margin-bottom: 2.1rem;
						}

						.question {
							font-size: 2.8rem;
							font-weight: 700;
							margin-bottom: 1.1rem;
							position: relative;
						}

						.answer {
							font-size: 2.4rem;
							font-weight: 500;
							line-height: 1.395;

							&.answer1 {
								margin-bottom: 9.8rem;
							}

							&.answer2 {
								margin-bottom: 8.5rem;
							}
						}
					}

					& > img:not(.arrow) {
						position: absolute;
						left: 0;
						bottom: 0;
					}

					& > img.arrow {
						position: absolute;
						right: 27.5rem;
						top: 2.8rem;
					}
				}

				&.sponsorship {
					padding-top: 14.6rem;
					display: flex;
					justify-content: space-between;
					padding-bottom: 17rem;
					box-sizing: border-box;
					position: relative;
					height: 91.6rem;

					.top {
						position: absolute;
						top: 0;
						left: 0;
						width: 100vw;
						height: 0.01rem;
					}

					& > .left {
						position: relative;
						margin-left: 17.9rem;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.blueStar {
							position: absolute;
							top: -9.6rem;
						}

						.title {
							font-family: 'Tango Sans';
							font-size: 5.8rem;
							line-height: 0.948;
							font-weight: 700;
							color: var(--color-sub-blue);
							margin-bottom: 1.8rem;
						}

						.subtitle {
							font-size: 3.2rem;
							letter-spacing: -0.065em;
						}
					}

					& > .right {
						margin-right: 17.9rem;
						width: 100%;

						.main-sponsor {
							font-family: 'Tango Sans';
							font-size: 3.4rem;
							line-height: 0.948;
							font-weight: 700;
							border-bottom: 0.3rem solid #cccccc;
							margin-bottom: 4rem;
							padding-bottom: 2.5rem;
						}

						.sponsorships {
							display: grid;
							grid-template-columns: repeat(3, 1fr);
							text-align: center;
							margin-bottom: 4.6rem;

							& > div {
								& > div:first-of-type {
									display: flex;
									justify-content: center;
									align-items: center;
									width: 20vw;
									min-height: 6.928rem;
									margin-bottom: 1.572rem;
								}

								& > div:nth-of-type(2) {
									font-size: 1.6vw;
									font-weight: 700;
									letter-spacing: -0.065em;
									margin-bottom: 0.7rem;
								}

								& > div:last-of-type {
									font-size: 1.25vw;
									line-height: 1.305;
									letter-spacing: -0.065em;
								}
							}
						}

						& > div:last-of-type {
							width: 100%;
							display: flex;
							background-color: var(--color-sub-blue);
							border-radius: 3.4rem;
							padding: 6.6rem 6rem;
							box-sizing: border-box;

							.left {
								position: relative;
								margin-right: 6rem;

								& > div {
									font-family: 'Tango Sans';
									font-weight: 700;
									font-size: 4.3rem;
									line-height: 0.948;

									&:first-of-type {
										margin-bottom: 1.7rem;
									}
								}

								.whiteStar {
									position: absolute;
									left: 10.75rem;
								}
							}

							.right {
								& > div {
									font-size: 3.3rem;
									font-weight: 700;
									line-height: 1.285;
									letter-spacing: -0.065em;
									margin-bottom: 1.6rem;
								}

								& > button {
									background-color: var(--color-sub-yellow);
									text-align: center;
									width: 19.1rem;
									height: 6.7rem;
									border-radius: 1.4rem;
									font-size: 2.4rem;
									font-weight: 700;
									letter-spacing: -0.045rem;
									color: var(--color-main-black);
								}
							}
						}
					}

					& > button.front {
						position: absolute;
						left: 18rem;
						bottom: 17.7rem;
						display: flex;
						align-items: center;
						gap: 0.8rem;
						background-color: #2f2f2f;
						padding: 2.15rem;
						font-size: 1.8rem;
						font-weight: 500;
						line-height: 0.948;
						border-radius: 3.4rem;
						width: max-content;

						& > div {
							font-family: 'Montserrat';
							font-size: inherit;
						}
					}
				}
			}
		}
	}

	.swiper-pagination-progressbar {
		position: fixed;
		bottom: 1.5rem;
		left: 0;
		right: 0;
		z-index: 50;
		width: 126rem;
		height: 1rem;
		margin: 0 auto;
		overflow: hidden;
		background-color: var(--color-sub-gray);
		border-radius: 0.8rem;
		opacity: 1;
		transition: all 0.2s ease-in-out;

		&.inactive {
			opacity: 0;
			transform: translateY(1rem);
		}

		.swiper-pagination-progressbar-fill {
			display: block;
			height: 1rem;
			background-color: var(--color-main-white);
			border-radius: 0.8rem;
			transition: width 0.2s ease-in-out;
		}
	}

	@media screen and (max-width: 1279px) {
		min-width: unset;
		width: 100%;
		height: 100dvh;
		overflow: hidden;

		.swiper-button-prev,
		.swiper-button-next {
			visibility: visible;
			opacity: 1;
			transition: all 0.2s ease-in-out;

			&::after {
				content: '';
			}

			&.swiper-button-disabled {
				visibility: hidden;
				opacity: 0;
			}
		}

		.swiper-wrapper {
			width: 100%;
			height: 100dvh;

			.swiper-slide {
				min-height: unset;
				height: calc(100dvh - 8.4rem);
				width: 100vw;
				margin-top: 8.6rem;

				&:has(.sponsorship) {
					margin-top: 8.6rem;
				}

				&.duplicate {
					padding-top: 20rem;
				}

				& > section {
					width: 100%;
					box-sizing: border-box;

					&.main {
						min-width: unset;
						width: 100vw;

						.video {
							width: 100vw;
							height: calc(100dvh - 8.6rem);
						}
					}

					&.introduction {
						min-width: unset;
						box-sizing: border-box;
						width: 100vw;
						flex-direction: column;
						justify-content: center;
						margin-top: 0;
						padding: 0 2.5rem 0 5rem;
						height: calc(100dvh - 8.6rem);

						.left {
							margin: 0;
							font-size: 4rem;
							line-height: 1.08;
							letter-spacing: -0.01rem;
							margin-bottom: 3.9rem;

							.star {
								width: 2.2rem;
								top: -0.9rem;
								left: 3.6rem;
							}
						}

						.right {
							.title {
								font-size: 2rem;
								line-height: 1.33;
								letter-spacing: -0.04rem;
								margin-bottom: 2.7rem;

								.check {
									width: 9.5rem;
									height: 6.1rem;
									top: 2rem;
									left: -1.7rem;
								}
							}

							.contents {
								font-size: 1.4rem;
								margin-bottom: 1.9rem;
								word-break: keep-all;
								padding-right: 2.5rem;

								&:last-of-type {
									margin-bottom: 0;
								}
							}
						}
					}

					&.presenters {
						min-width: 100vw;
						width: 100vw;
						height: calc(100dvh - 8.6rem);

						.top {
							width: 100vw;
						}

						.video-box {
							height: calc(100dvh - 8.6rem);
							display: flex;
							align-items: center;

							.video {
								width: 100vw;
							}

							& > div {
								width: 100vw;
								margin-top: 0;

								& > img {
									width: 5.9rem;
									height: 5.9rem;
									margin-bottom: 1.4rem;
								}

								& > div:first-of-type {
									font-size: 2rem;
									margin-bottom: 2.2rem;
								}

								& > div:last-of-type {
									font-size: 1.4rem;
									padding: 0 6.6rem;
									word-break: keep-all;
									row-gap: 1.4rem;
									margin-bottom: 2.2rem;
								}

								& > button {
									width: 21.2rem;
									padding: 1.2rem 0;
									font-size: 1.6rem;
									border-radius: 1.2rem;
								}
							}
						}
					}

					&.timetable {
						padding: 0 2rem;
						padding-bottom: 100dvh;

						.head {
							margin: 0;
							width: 100%;
							padding-top: 2.6rem;
							padding-bottom: 4.3rem;
							border-bottom: 0.2rem solid #cccccc;

							img {
								width: 4.5rem;
								height: 4.5rem;
								margin-bottom: 0.7rem;
							}

							.title {
								font-size: 2.4rem;
								line-height: 1.033;
								margin-bottom: 0.7rem;
							}

							.desc {
								font-size: 1.6rem;
								line-height: 1.33;
								letter-spacing: -0.04em;
							}
						}

						.body {
							width: 100%;
							margin: 0;

							.schedule {
								padding-top: 1.3rem;
								padding-bottom: 6.5rem;

								.date {
									font-size: 1.6rem;
									line-height: 1.108;
									margin-bottom: 2.2rem;
								}

								.presenters {
									grid-template-columns: 1fr;
									row-gap: 1.5rem;
								}
							}
						}
					}

					&.share {
						min-width: unset;
						width: 100vw;
						height: calc(100dvh - 8.6rem);
						justify-content: center;

						& > img {
							width: 31.7rem;
							height: 10.4rem;
							margin-top: 0;
							margin-bottom: 0.7rem;
						}

						& > div:first-of-type {
							font-size: 2rem;
							line-height: 1.33;
							letter-spacing: -0.04rem;
							word-break: keep-all;
							margin-bottom: 3.9rem;
							padding: 0 2rem;
						}

						& > div:last-of-type {
							column-gap: 1.2rem;

							button {
								width: 15.4rem;
								height: 4.4rem;
								border-radius: 1.2rem;
								font-size: 1.4rem;
							}

							.kakao {
								gap: 0.4rem;
							}

							.link {
								gap: 0.6rem;
							}
						}
					}

					&.faq {
						min-width: unset;
						width: 100vw;
						height: calc(100dvh - 8.6rem);
						padding-top: 9.5rem;
						flex-direction: column;
						padding-left: 3.2rem;

						img.arrow {
							top: 2.6rem;
							right: 5rem;
						}

						.left {
							margin: 0;
							margin-bottom: 4.6rem;

							& > img {
								width: 4.5rem;
								height: 4.5rem;
								top: -4.9rem;
								left: 0;
							}

							& > div:first-of-type {
								margin-bottom: 0.5rem;
								font-size: 5.2rem;
								line-height: 0.948;
							}

							& > div:last-of-type {
								font-size: 1.6rem;
							}
						}

						.right {
							.line {
								width: 27.7rem;
								height: 0.2rem;
								margin-bottom: 1.8rem;
							}

							.question {
								font-size: 1.4rem;
								margin-bottom: 0.5rem;
							}

							.answer {
								font-size: 1.2rem;
								font-weight: 300;
								word-break: keep-all;

								&.answer1,
								&.answer2 {
									margin-bottom: 4.7rem;
								}

								&.answer3 {
									padding-right: 5rem;
								}
							}
						}

						& > img:not(.arrow) {
							width: 23.9rem;
							height: 22.079rem;
							z-index: -1;
						}
					}

					&.sponsorship {
						min-width: unset;
						width: 100vw;
						flex-direction: column;
						justify-content: start;
						align-items: center;
						padding: 3.2rem 2rem 0;
						box-sizing: border-box;

						.top {
							width: 0.01rem;
							height: 0.01rem;
							right: 0;
							top: 0;
							left: unset;
						}

						.left {
							margin: 0;
							width: 100%;
							margin-bottom: 3.9rem;

							.blueStar {
								width: 4.5rem;
								height: 4.5rem;
								top: -5.1rem;
								left: 50%;
								transform: translateX(-50%);
							}

							.text {
								text-align: center;

								.title {
									font-size: 3.4rem;
									line-height: 0.983;
									margin-bottom: 0.8rem;
								}

								.subtitle {
									font-size: 1.6rem;
									line-height: 1.33;
									letter-spacing: -0.04rem;
								}
							}
						}

						.right {
							width: 32rem;
							flex-direction: column;
							margin: 0;
							margin-bottom: 7.8rem;

							.sponsorships {
								grid-template-rows: repeat(3, 1fr);
								grid-template-columns: 1fr;
								gap: 3.3rem;
								margin-bottom: 3.5rem;

								& > div {
									display: flex;
									flex-direction: column;
									justify-content: center;

									& > div:first-of-type {
										min-height: max-content;
										margin-bottom: 1.2rem;
									}

									& > div:nth-of-type(2) {
										font-size: 1.6rem;
										margin-bottom: 0.3rem;
									}

									& > div:last-of-type {
										font-size: 1.4rem;
									}
								}
							}

							& > div:last-of-type {
								flex-direction: column;
								padding: 3.9rem 2.55rem 3.5rem;
								text-align: center;

								.left {
									margin: 0;
									margin-bottom: 4.2rem;

									& > div:first-of-type {
										font-size: 2.4rem;
										line-height: 0.983;
										margin-bottom: 0.9rem;
									}

									& > div:last-of-type {
										font-size: 2.8rem;
										display: flex;
										justify-content: center;
										align-items: center;
									}

									.whiteStar {
										position: unset;
										margin-left: 1rem;
										margin-bottom: 0.2rem;
										width: 2.1rem;
										height: 2.1rem;
									}
								}

								.right {
									width: 100%;
									margin: 0;

									& > div {
										padding: 0;
										margin-bottom: 1.9rem;
										font-size: 1.8rem;
										line-height: 1.33;
										letter-spacing: -0.04rem;
									}

									& > button {
										width: 12.3rem;
										height: 4.4rem;
										font-size: 1.6rem;
										border-radius: 1.2rem;
									}
								}
							}
						}

						button.front {
							position: unset;
							left: unset;
							bottom: unset;
							display: flex;
							width: 21.8rem;
							height: 6rem;
							padding: 1.55rem 2.15rem;
							justify-content: center;

							& > div {
								font-family: 'Montserrat';
							}
						}
					}
				}
			}
		}

		.swiper-pagination-progressbar {
			bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
			bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
			width: 32rem;
			height: 0.7rem;

			.swiper-pagination-progressbar-fill {
				height: 0.7rem;
			}
		}
	}
`;

export const SecondStyledSwiper = styled(Swiper)`
	height: 91.6rem;
	overflow: hidden;
	position: relative;

	.swiper-wrapper {
		height: 100%;
		flex-direction: column;
	}

	.floating-button {
		position: absolute;
		bottom: 9rem;
		right: 11.6rem;
		width: 9.2rem;
		height: 9.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5rem;
		background: #2d2d2d;
		box-shadow: 0 0.4rem 0.4rem 0.6rem rgba(53, 53, 53, 0.05);
		transition: all 0.2s ease-in-out;

		img {
			width: 5.367rem;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}

		&.hidden {
			opacity: 0;
			visibility: hidden;
		}
	}

	@media screen and (max-width: 1279px) {
		height: calc(100dvh - 8.6rem);

		.swiper-wrapper {
			height: calc(100dvh - 8.6rem);

			.swiper-slide {
				margin-top: 0;
			}
		}

		.floating-button {
			width: 5.2rem;
			height: 5.2rem;
			bottom: 3rem;
			right: 2rem;

			img {
				width: 3.033rem;
			}
		}
	}
`;

export const ThirdStyledSwiper = styled(Swiper)`
	section {
		display: flex;
		flex-direction: column;
		padding-bottom: 100dvh;

		.head {
			padding: 9.3rem 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 20rem;
			text-align: center;

			img {
				width: 8.6rem;
				height: 8.6rem;
				margin-bottom: 1rem;
			}

			.title {
				font-family: 'Tango Sans';
				font-size: 5.8rem;
				font-weight: 700;
				line-height: 0.948;
				color: var(--color-sub-yellow);
				margin-bottom: 1.8rem;
			}

			.sub-title {
				font-size: 3.2rem;
				font-weight: 700;
				letter-spacing: -0.065em;
			}
		}

		.companies {
			margin: 0 20rem;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			row-gap: 10rem;

			.company {
				max-width: 37.5rem;
				display: flex;
				flex-direction: column;
				align-items: center;

				.icon {
					position: relative;
					margin-bottom: 2.7rem;
					cursor: pointer;

					.tag {
						position: absolute;
						padding: 0.8rem 1.5rem;
						top: 1.9525rem;
						left: -5rem;
						border-radius: 3.2rem;
						font-size: 2.2rem;
						font-weight: 700;
						line-height: 1.737;
						border: 0.1rem solid var(--color-main-white);

						&.blue {
							background-color: var(--color-sub-blue);
						}

						&.yellow {
							background-color: var(--color-sub-yellow);
						}
					}
				}

				.name {
					margin-bottom: 1rem;
					font-size: 3rem;
					font-weight: 700;
					letter-spacing: -0.065em;
				}

				.desc {
					font-size: 2.2rem;
					line-height: 1.672;
					letter-spacing: -0.045em;
					white-space: pre-line;
					text-align: center;

					&.smallDesc {
						font-size: 2rem;
					}

					&.xsmallDesc {
						font-size: 1.8rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1279px) {
		.swiper-wrapper {
			.swiper-slide {
				margin-top: 0;
			}

			section {
				.head {
					padding-top: 2.4rem;
					padding-bottom: 0;
					margin: 0;
					margin: 0 2rem;
					margin-bottom: 5.4rem;

					img {
						width: 4.5rem;
						height: 4.5rem;
						margin-bottom: 0.6rem;
					}

					.title {
						font-size: 3.4rem;
						line-height: 0.983;
						margin-bottom: 0.8rem;
					}

					.sub-title {
						font-size: 1.6rem;
						line-height: 1.33;
						letter-spacing: -0.04em;
					}
				}

				.companies {
					margin: 0 2rem;
					grid-template-columns: 1fr;
					row-gap: 7rem;

					.company {
						margin: 0 auto;
						width: 100%;
						max-width: 32rem;

						.icon {
							margin-bottom: 1.6rem;

							.tag {
								font-size: 1.4rem;
								line-height: 1.737;
								padding: 0.65rem 1.2rem;
								top: 1.6rem;
								left: -4.1rem;
							}

							img {
								width: 15.4rem;
								height: 15.4rem;
							}
						}

						.name {
							margin-bottom: 0.6rem;
							font-size: 1.8rem;
						}

						.desc {
							font-size: 1.4rem;
							line-height: 1.672;
							letter-spacing: -0.045em;
						}
					}
				}
			}
		}
	}
`;

export const LastStyledSwiper = styled(Swiper)`
	height: 91.6rem;

	.swiper-wrapper {
		display: flex;
		flex-direction: column;
		height: 91.6rem;

		.section.sponsorship-grade {
			img {
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1279px) {
		height: calc(100dvh - 8.4rem);

		.swiper-wrapper {
			height: calc((100dvh - 8.4rem) / 2) !important;

			.swiper-slide {
				margin-top: 10rem;
				height: calc((100dvh - 8.4rem) / 2) !important;

				&.sponsorship-slide {
					height: 121.5rem !important;
				}

				&.blank-space {
					padding-top: 17.5rem;

					.blank {
						width: 100%;
						height: calc((100dvh - 8.4rem) / 2) !important;
						background-color: transparent;
					}
				}

				& > section {
					min-width: 100vw;

					&.sponsorship {
						height: 121.5rem;
					}

					&.sponsorship-detail {
						margin-top: 70dvh;
						padding: 0 2rem;
						padding-bottom: 100dvh;

						.background-typo {
							font-size: 6rem;
							letter-spacing: 0.85rem;
							top: 0;
							left: -0.9rem;
						}

						.guide {
							padding-top: 8rem;
							flex-direction: column;
							align-items: center;
							text-align: center;
							margin-bottom: 4.6rem;

							.left {
								margin: 0;
								margin-bottom: 4.6rem;
								font-size: 2.4rem;
								letter-spacing: -0.04rem;

								& > img {
									width: 3.7rem;
									height: 3.7rem;
									top: -4.6rem;
									left: 50%;
									transform: translateX(-50%);
								}
							}

							.right {
								margin: 0;

								.title {
									font-size: 2rem;
									margin-bottom: 1rem;
								}

								.desc {
									font-size: 1.4rem;
									padding: 0 5rem;
									word-break: keep-all;
									margin-bottom: 1.8rem;
								}

								.buttons {
									padding: 0 2rem;
									column-gap: 1.2rem;
									justify-content: center;

									a,
									button {
										width: 15.4rem;
										height: 4.4rem;
										border-radius: 1.2rem;
										font-size: 1.4rem;
										padding: 0;
										display: flex;
										justify-content: center;
										align-items: center;
									}
								}
							}
						}

						.section {
							border-width: 0.2rem;
							margin: 0;
							padding-top: 2.2rem;
							padding-bottom: 3.1rem;
							max-width: 100vw;
							box-sizing: border-box;

							.title {
								font-size: 2rem;
								margin-bottom: 1.2rem;
							}

							& > img {
								max-width: calc(100vw - 4rem);
							}
						}
					}
				}
				/* } */
			}
		}

		section.sponsorship-detail > .buttons {
			margin: 0 auto;
			column-gap: 1.2rem;
			justify-content: center;

			* {
				width: 15.4rem;
				height: 4.4rem;
				border-radius: 1.2rem;
				font-size: 1.4rem;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
`;

export const BoothStyledSwiper = styled(Swiper)`
	@media screen and (max-width: 1279px) {
		.swiper-wrapper {
			display: flex;
			flex-direction: column;

			.swiper-slide {
				margin-top: 0;

				section.booth {
					margin: 0 auto;
					min-width: unset;
					width: 100vw;
					max-width: 36rem;
					overflow: visible;
					box-sizing: border-box;
					height: unset;
					padding-bottom: 100dvh;

					.booth-box {
						width: 100%;
						height: max-content;
						max-width: 32rem;
						margin-top: 4.4rem;
						flex-direction: column;

						.booth-box-left {
							width: 100%;
							padding: 4rem 4rem 3rem;
							box-sizing: border-box;
							border-right: none;
							border-bottom: 0.1rem solid var(--color-main-white);

							.booth-box-left-title {
								top: 1.6rem;
								left: 1.6rem;
								font-size: 1.8rem;
								line-height: 1.33;
								letter-spacing: 0.08em;
							}

							.booth-box-left-image {
								padding: 0;

								img {
									width: 24rem;
									height: 26.8rem;
								}
							}
						}

						.booth-box-right {
							width: 100%;
							height: max-content;

							.booth-box-right-section {
								justify-content: unset;
								padding: 2.4rem 1.7rem;
								font-size: unset;

								.block {
									width: max-content;
									height: max-content;
									font-size: 1.6rem;
									line-height: 1.33;
									letter-spacing: 0.08em;
									padding: 0.4rem 1.5rem;
									margin-bottom: 1.2rem;
								}

								.title {
									font-size: 1.6rem;
									line-height: 1.33;
									margin-bottom: 0.3rem;
								}

								.desc {
									font-size: 1.4rem;
									line-height: 1.61;
									letter-spacing: -0.04em;
									word-break: keep-all;
								}
							}
						}
					}
				}
			}
		}
	}
`;
