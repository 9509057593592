import styled, { keyframes } from 'styled-components';
import logo from '../assets/svgs/neordinary_logo.svg';
import dropdown from '../assets/svgs/ic_dropdown.svg';
import star01 from '../assets/svgs/star_01.svg';
import star02 from '../assets/svgs/star_02.svg';
import { useState } from 'react';

const menus = [
	{
		id: 1,
		line: 1,
		menu: '행사 소개',
	},
	{
		id: 3,
		line: 1,
		menu: '발표 프로그램',
	},
	{
		id: 4,
		line: 1,
		menu: '참여후원사',
	},
	{
		id: 5,
		line: 1,
		menu: '공유하기',
	},
	{
		id: 6,
		line: 2,
		menu: 'FAQ',
	},
	{
		id: 7,
		line: 2,
		menu: '기업 후원 안내',
	},
];

const Header = ({ swiper, firstSwiperCurrentPage }) => {
	const [dropdownOpened, setDropdownOpened] = useState(false);

	return (
		<HeaderStyled>
			<div>NE(O)RDINARY DEMODAY 2023</div>
			<div>
				<div>
					<div className="logo-container">
						<img
							className="logo"
							src={logo}
							alt="너디너리"
							onClick={() => swiper.current.slideTo(0)}
						/>
						<div
							className={`sub-menu-dropdown${dropdownOpened ? ' opened' : ''}`}
							onClick={() => setDropdownOpened((prev) => !prev)}
						>
							<span>MENU</span>
							<img src={dropdown} alt="dropdown" />
						</div>
					</div>
					<div className={`menus${dropdownOpened ? ' opened' : ''}`}>
						{menus.map(({ menu, id }, index) => (
							<div
								key={index}
								className={
									firstSwiperCurrentPage === 0
										? ''
										: firstSwiperCurrentPage === id ||
										  (firstSwiperCurrentPage === 2 && id === 1)
										? 'active'
										: 'inactive'
								}
								onClick={() => {
									setDropdownOpened(false);
									swiper.current.slideTo(id);
								}}
							>
								{menu}
							</div>
						))}
						<div className="mobileOnly" />
					</div>
					<button
						className={`button${
							firstSwiperCurrentPage === 6 ? ' animation' : ' no-animation'
						}`}
						type="button"
						onClick={() =>
							window.open('https://event-us.kr/neordinary/event/68943')
						}
					>
						<span>신청하기</span>
						<img
							className={`star-01${
								firstSwiperCurrentPage === 6 ? ' animation' : ' no-animation'
							}`}
							src={star01}
							alt="star"
						/>
						<img
							className={`star-02${
								firstSwiperCurrentPage === 6 ? ' animation' : ' no-animation'
							}`}
							src={star02}
							alt="star"
						/>
					</button>
				</div>
			</div>
		</HeaderStyled>
	);
};
export default Header;

const starAnimation01 = keyframes`
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(0);
	}
`;

const starAnimation02 = keyframes`
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
`;

const buttonAnimation01 = keyframes`
	0% {
		transform: rotate(0deg);
		transform-origin: left;
	}

	25% {
		transform: rotate(10deg);
		transform-origin: center;
	}

	75% {
		transform: rotate(-10deg);
		transform-origin: center;
	}

	100% {
		transform: rotate(0deg);
		transform-origin: right;
	}
`;

const HeaderStyled = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	width: 100vw;
	height: 16.2rem;
	color: white;
	text-align: center;
	background-color: black;

	& > div {
		border-bottom: 0.1rem solid white;
	}

	& > div:first-of-type {
		font-family: 'Montserrat';
		font-weight: 600;
		font-size: 2.8rem;
		height: 8.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& > div:last-of-type {
		margin: 0 auto;

		& > div {
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 7.8rem;
			padding: 0 4rem;
			max-width: 180rem;
			width: 100%;
			margin: 0 auto;
			box-sizing: border-box;

			.logo-container {
				display: flex;
				align-items: center;

				.logo {
					display: block;
					cursor: pointer;
				}

				.sub-menu-dropdown {
					display: none;

					span {
						font-family: 'Montserrat';
						font-weight: 600;
						font-size: 1.4rem;
						letter-spacing: -0.085rem;
					}
				}
			}

			.menus {
				display: flex;

				& > div {
					margin: 0 1.95rem;
					font-family: 'Spoqa Han Sans Neo';
					font-weight: 400;
					font-size: 1.8vw;
					transition: all 0.2s ease-in-out;
					cursor: pointer;

					&.active {
						color: var(--color-main-white);
					}

					&.inactive {
						color: var(--color-sub-gray);
					}
				}

				.mobileOnly {
					display: none;
					position: absolute;
					top: 4.2rem;
					margin: 0;
					width: 100vw;
					height: 0.1rem;
					background-color: var(--color-main-white);
				}
			}

			button {
				padding: 1.2rem 1.75rem;
				border-radius: 1.1rem;
				position: relative;

				& > span {
					font-size: 2.6rem;
				}

				&.animation {
					animation: ${buttonAnimation01} 1.5s linear infinite;
				}

				& > img {
					position: absolute;
					animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
					animation-iteration-count: infinite;
					transition: all 0.2s ease-in-out;

					&.star-01 {
						top: -0.5rem;
						left: -0.2rem;
						animation-duration: 1s;
						animation-name: ${starAnimation01};
					}

					&.star-02 {
						bottom: 0;
						right: -1rem;
						animation-duration: 2s;
						animation-name: ${starAnimation02};
					}

					&.animation {
						opacity: 1;
					}

					&.no-animation {
						opacity: 0;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1279px) {
		width: 100vw;
		height: max-content;
		margin: 0 auto;

		& > div:first-of-type {
			font-size: 1.4rem;
			height: 4.2rem;
		}

		& > div:last-of-type {
			& > div {
				height: 4.2rem;
				padding: 0 2rem;
				position: relative;
				justify-content: space-between;

				.logo-container {
					.logo {
						width: 9.1rem;
						height: 3rem;
						margin-right: 0.3rem;
					}

					.sub-menu-dropdown {
						display: flex;
						column-gap: 0.5rem;
						cursor: pointer;

						& > img {
							transition: all 0.2s ease-in-out;
						}

						&.opened > img {
							transform: rotate(-180deg);
						}
					}
				}

				.menus {
					width: 100vw;
					height: max-content;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					border-bottom: 0.1rem solid var(--color-main-white);
					transform: translateY(-10%);
					opacity: 0;
					z-index: -1;
					transition: all 0.2s ease-in-out;
					background-color: var(--color-main-black);
					visibility: hidden;

					& > div {
						font-size: 1.4rem;
						letter-spacing: -0.045rem;
						margin: 1.2rem 1.1rem;
					}

					&.opened {
						transform: translateY(0);
						opacity: 1;
						visibility: visible;
					}

					.mobileOnly {
						display: block;
					}
				}

				button {
					padding: 0.6rem 1rem;
					letter-spacing: -0.045rem;
					margin-left: 0;
					font-weight: 500;

					& > span {
						font-size: 1.4rem;
					}
				}
			}
		}
	}
`;
