import iconStar from '../../assets/svgs/ic_star_86px.svg';
import PresenterCard from '../PresenterCard';
import imageProfile01 from '../../assets/images/img_profile01.png';
import imageProfile02 from '../../assets/images/img_profile02.png';
import imageProfile03 from '../../assets/images/img_profile03.png';
import imageProfile04 from '../../assets/images/img_profile04.png';
import imageProfile05 from '../../assets/images/img_profile05.png';
import imageProfile06 from '../../assets/images/img_profile06.png';
import imageProfile07 from '../../assets/images/img_profile07.png';
import imageProfile08 from '../../assets/images/img_profile08.png';
import imageProfile09 from '../../assets/images/img_profile09.png';
import imageProfile10 from '../../assets/images/img_profile10.png';
import imageProfile11 from '../../assets/images/img_profile11.png';
import imageProfile12 from '../../assets/images/img_profile12.png';
import imageProfile13 from '../../assets/images/img_profile13.png';
import imageProfile14 from '../../assets/images/img_profile14.png';
import imageProfile15 from '../../assets/images/img_profile15.png';
import imageProfile16 from '../../assets/images/img_profile16.png';
import imageProfile17 from '../../assets/images/img_profile17.png';
import imageProfile18 from '../../assets/images/img_profile18.png';
import imageProfile19 from '../../assets/images/img_profile19.png';
import imageProfile20 from '../../assets/images/img_profile20.png';

const schedules = {
	friday: [
		{
			tag: '기술 성장',
			name: '정우현(제리)',
			image: imageProfile01,
			belongTo: '컴공선배',
			startAt: '13:00',
			endAt: '13:30',
			content: '키워드로 알아보는 백엔드 로드맵',
			careers: [
				'현) 유튜브 컴공선배 Creator',
				'현) 소프트스퀘어드 CTO',
				'현) SW 마에스트로 멘토\n(과학기술정보통신부 장관 임명)',
				'미미박스 Tech Platform Backend Developer',
				'넥솔시스템 Samsonite Korea 총괄개발책임자',
			],
		},
		{
			tag: '커리어 성장',
			name: '안정현',
			image: imageProfile02,
			belongTo: '데브시스터즈',
			startAt: '13:40',
			endAt: '14:10',
			content: '비전공자의 커리어 성장기',
			careers: [
				'현) 데브시스터즈 Backend Developer',
				'주식회사 크리에이트립 Backend Developer',
				'아이넷캅 Backend Developer',
			],
		},
		{
			tag: '협업&생산성&일하는 방법',
			name: '이상아',
			image: imageProfile03,
			belongTo: 'ALAM',
			startAt: '14:20',
			endAt: '14:50',
			content: '공간컴퓨팅과 가상 세계의 몰입 공간 확장',
			smallText: true,
			careers: [
				'현) ALAM Team Leader',
				'KEA 가상증강현실 연구원',
				'서강대학교 아트앤테크놀로지 박사',
				'Osnabruck University 인지과학 석사',
				'University of Minnesota 심리학 학사',
			],
		},
		{
			tag: '기술 성장',
			name: '문동욱',
			image: imageProfile04,
			belongTo: '쿼타랩',
			startAt: '15:30',
			endAt: '16:00',
			content: '시장에서 살아남는 개발자 되기',
			careers: [
				'현) 쿼타랩 Frontend Chapter Lead',
				'비바리퍼블리카 Frontend Engineer, F-Lead',
				'Lubycon Frontend Engineer',
			],
		},
		{
			tag: '커리어 성장',
			name: '추병조',
			image: imageProfile05,
			belongTo: '라이프멘토스',
			startAt: '16:10',
			endAt: '16:40',
			content: '사이드프로젝트/일정관리',
			careers: [
				'현) 라이프멘토스 CEO',
				'KT IT부문 SW개발본부',
				'KT 플랫폼사업기획실 SW개발센터',
				'KT 융합기술원 SW개발센터',
				'KT G&E부문 SW개발센터',
				'현대 유엔아이(현대그룹IT) 연구소',
			],
		},
		{
			tag: '기술 성장',
			name: '이정환',
			image: imageProfile06,
			belongTo: '프리랜서',
			startAt: '16:40',
			endAt: '17:10',
			content: 'React.js의 렌더링 방식 살펴보기',
			careers: [
				'2023 인프콘 발표자',
				'전) 스타트업 22Hours Co-Founder & CTO',
				'한 입 크기로 잘라먹는 리액트 강의 & 도서 출간',
				'한 입 크기로 잘라먹는 타입스크립트 강의 출간',
				'OKKY 월간 세미나 : React가 처음인 당신',
				'코드잇 KDT 프론트엔드 부트캠프 특강',
				'한입 프론트엔드 커뮤니티 운영',
			],
		},
		{
			tag: '기술 성장',
			name: '최선규',
			image: imageProfile07,
			belongTo: '가천대학교',
			startAt: '17:40',
			endAt: '18:10',
			content: '헤딩 드리븐 성장기',
			careers: [
				'가천대학교 AI•SW학부(인공지능전공) 22학번',
				'라이징캠프 12기 Spring Boot 트랙 멘토',
				'4th 가천대 UMC Plan 파트장',
				'가천대학교 SIL Lab. Research Assistant',
			],
		},
		{
			tag: '기술 성장',
			name: '한재엽',
			image: imageProfile08,
			belongTo: '토스페이먼츠',
			startAt: '18:20',
			endAt: '18:50',
			content: '개발자의 학과 습',
			careers: [
				'현) 토스페이먼츠 Frontend Chapter Lead',
				'토스(비바리퍼블리카) Front-End Engineer',
				'라인파이낸셜플러스 Web Dev 2 / Front-End\nEngineer',
				'네이버 Front-End Engineer',
			],
		},
	],
	saturday: [
		{
			tag: '커리어 성장',
			name: '맹윤호',
			image: imageProfile09,
			belongTo: '이화여자대학교',
			startAt: '11:00',
			endAt: '11:30',
			content:
				'경기침체 속 취업/창업/학업의 기로에 서서\n고민하고 있는 당신에게',
			smallText: true,
			multiline: true,
			careers: [
				'현) 이화여자대학교 겸임교수',
				'<코딩진로>, <머신러닝 디자인패턴>,\n<Do it 강화학습> 저자',
				'스타트업 CTO',
				'IBM Data&AI 엔지니어',
				'연세대 석사졸업, 박사 수료',
			],
		},
		{
			tag: '협업&생산성&일하는 방법',
			name: '정지원',
			image: imageProfile10,
			belongTo: '레벨스',
			startAt: '11:40',
			endAt: '12:10',
			content: '피그마로 협업하기',
			careers: [
				'현) 레벨스 Product Designer',
				'<UX/UI 디자이너를 위한 실무 피그마> 저자',
				'해외 디자인 상 수상, 컨퍼런스, 강연 경험',
				'한국예술종합학교 커뮤니케이션 디자인 전공',
			],
		},
		{
			tag: '기술 성장',
			name: '김흥래, 서동우',
			images: [imageProfile11, imageProfile12],
			belongTo: '자바카페',
			startAt: '12:20',
			endAt: '12:50',
			content:
				'등골이 오싹했던 트러블슈팅 경험기\n(서비스 운영하면서 짤릴뻔한 순간들)',
			smallText: true,
			multiline: true,
			careers: [
				{
					name: '김흥래',
					career: ['현) 자바카페 운영진', '현) NAVER Cloud 소프트웨어 개발자'],
				},
				{
					name: '서동우',
					career: ['현) 자바카페 운영진', '현) NAVER Cloud 소프트웨어 개발자'],
				},
			],
		},
		{
			tag: '기술 성장',
			name: '최용호',
			image: imageProfile13,
			belongTo: '자바카페',
			startAt: '13:20',
			endAt: '13:50',
			content: '개발자를 위한 소프트 스킬',
			careers: ['현) 자바카페 운영진', '현) AWS 솔루션 아키텍트'],
		},
		{
			tag: '협업&생산성&일하는 방법',
			name: '송요창',
			image: imageProfile14,
			belongTo: '우아한형제들',
			startAt: '14:00',
			endAt: '14:30',
			content: '내가 만난 일잘러 신입의 특징\nPart1, Part2',
			smallText: true,
			multiline: true,
			careers: [
				'현) 우아한형제들 Frontend Developer',
				'야놀자 Backend Developer',
				'Araso Pandan Backend Developer',
				'(주)성우효광 모비딕스 Software Developer',
			],
		},
		{
			tag: '커리어 성장',
			name: '이복음',
			image: imageProfile15,
			belongTo: '자바카페',
			startAt: '14:40',
			endAt: '15:10',
			content: '하고 싶은 것만 할 순 없잖아?\n레거시 환경 속에서 NEXT 준비하기',
			smallText: true,
			multiline: true,
			careers: ['현) 자바카페 운영진', 'NAVER Cloud 소프트웨어 개발자'],
		},
		{
			tag: '커리어 성장',
			name: '송주영',
			image: imageProfile16,
			belongTo: 'LG U+',
			startAt: '15:40',
			endAt: '16:10',
			content: 'Junior들을 위한,\n너무 늦게 알아버린, 너무 일찍 알게된',
			smallText: true,
			multiline: true,
			careers: [
				'현) LG 유플러스 CTO 서비스플랫폼빌드그룹\n연구위원',
				'Weverse Company DevOps Lead',
				'삼성전자 무선사업부 DevOps 엔지니어',
				'AWS 한국 사용자그룹 DevOps 운영진',
				'AWS Container Hero',
				'Hashicorp Ambassador 2022',
			],
		},
		{
			tag: '협업&생산성&일하는 방법',
			name: '하조은',
			image: imageProfile17,
			belongTo: '당근마켓',
			startAt: '16:20',
			endAt: '16:50',
			content: '내가 지키지 못한 조언들',
			careers: [
				'현) 당근마켓 Software Developer',
				'뱅크샐러드 Software Developer',
				'마플코퍼레이션 Software Developer',
			],
		},
		{
			tag: '커리어 성장',
			name: '박상권',
			image: imageProfile18,
			belongTo: 'PRND 컴퍼니 (헤이딜러)',
			startAt: '17:00',
			endAt: '17:30',
			content: '나는 어떤 회사에서 일해야 할까?\n(대기업? 스타트업?)',
			smallText: true,
			multiline: true,
			careers: [
				'현) 피알앤디컴퍼니 Android Team Leader',
				'하이퍼커넥트 Android Developer',
				'선의의 경쟁 Developer & Co-Founder',
				'대신증권 Software Engineer',
			],
		},
		{
			tag: '기술 성장',
			name: '양수열',
			image: imageProfile19,
			belongTo: 'Caremind',
			startAt: '18:00',
			endAt: '18:30',
			content: '우리의 기술성장은\n어디를 지향하고 있는가?',
			smallText: true,
			multiline: true,
			careers: [
				'현) Caremind CTO',
				'Java Champion, Oracle ACE',
				'TreasureData Engineer',
			],
		},
		{
			tag: '기술 성장',
			name: '강민철',
			image: imageProfile20,
			belongTo: '고려대학교',
			startAt: '18:40',
			endAt: '19:10',
			content: "살아남는 개발자가 되기 위한\nCS 전공 지식 '제대로' 학습법",
			smallText: true,
			multiline: true,
			careers: [
				'<혼자 공부하는 컴퓨터 구조+운영체제>,\n<모두의 깃&깃허브> 저자',
			],
		},
	],
};

export default function TimeTable({ mobile }) {
	return (
		<section id="time-table" className="timetable">
			<div className="head">
				<img src={iconStar} alt="star" />
				<div className="title">
					PRESENTATION
					<br />
					TIME TABLE
				</div>
				<div className="sub-title">발표 타임테이블</div>
			</div>
			<div className="body">
				<div className="schedule">
					<div className="date">9/8 (금요일)</div>
					<div className="presenters">
						{schedules.friday.map(
							(
								{
									tag,
									name,
									image,
									belongTo,
									startAt,
									endAt,
									content,
									smallText,
									multiline,
									careers,
								},
								index
							) => (
								<PresenterCard
									key={index}
									mobile={mobile}
									tag={tag}
									name={name}
									image={image}
									belongTo={belongTo}
									startAt={startAt}
									endAt={endAt}
									content={content}
									smallText={smallText}
									multiline={multiline}
									careers={careers}
								/>
							)
						)}
					</div>
				</div>
				<div className="schedule">
					<div className="date">9/9 (토요일)</div>
					<div className="presenters">
						{schedules.saturday.map(
							(
								{
									tag,
									name,
									images,
									image,
									belongTo,
									startAt,
									endAt,
									content,
									smallText,
									multiline,
									careers,
								},
								index
							) => (
								<PresenterCard
									key={index}
									mobile={mobile}
									tag={tag}
									images={images}
									image={image}
									name={name}
									belongTo={belongTo}
									startAt={startAt}
									endAt={endAt}
									content={content}
									smallText={smallText}
									multiline={multiline}
									careers={careers}
								/>
							)
						)}
					</div>
				</div>
			</div>
		</section>
	);
}
