import imageBoothDetails from '../../assets/images/img_booth.png';

export default function BoothDetails({ mobile }) {
	return (
		<section className="booth">
			<div className="booth-box">
				<div className="booth-box-left">
					<div className="booth-box-left-title">
						BOOTH
						<br />
						DETAILS.
					</div>
					<div className="booth-box-left-image">
						<img src={imageBoothDetails} alt="" />
					</div>
				</div>
				<div className="booth-box-right">
					<div className="booth-box-right-section">
						<div className="block">5F</div>
						<div className="title">너드들이 모여 지식을 나누는 공간</div>
						<div className="desc">
							기술 성장, 커리어 성장, 협업&생산성&일하는 방법에 대해
							{!mobile && <br />} 너드들만의 지식을 공유하고 함께 성장하는
							공간으로, 컨퍼런스가 진행됩니다.
						</div>
					</div>
					<div className="booth-box-right-section">
						<div className="block">1F</div>
						<div className="title">
							세상을 바꾸고 있는 너드들의 플레이그라운드
						</div>
						<div className="desc">
							세상을 바꾸기 위해 도전하는 서비스를
							<br />
							만들어가는 기업 부스가 운영됩니다.
						</div>
					</div>
					<div className="booth-box-right-section">
						<div className="block">B1F</div>
						<div className="title">
							세상을 바꾸고 싶은 너드들의 Fresh Idea를 보여주는 공간
						</div>
						<div className="desc">
							IT 동아리와 IT 유관 브랜드 및 기업이 함께{!mobile && <br />}
							{mobile && ' '}
							어우러지는 공간으로 운영됩니다.
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
