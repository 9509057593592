export default function Main({ mobile }) {
	return (
		<section className="main">
			<video className="video" autoPlay muted playsInline loop>
				{!mobile ? (
					<source src="videos/NEORDINARY_MAIN_PC.mp4" type="video/mp4" />
				) : (
					<source src="videos/NEORDINARY_MAIN_MOBILE.mp4" type="video/mp4" />
				)}
			</video>
		</section>
	);
}
